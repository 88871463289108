import { Store } from "pullstate";

const ShopStore = new Store({
    language: 'de',
    locale: 'de-CH',
    basket: {},
    basePath: '',
    shopConfiguration: {}
});

export default ShopStore;