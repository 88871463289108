/********************************************************************************************
* 
* Plugin:			navigationMobile
* Beschreibung:		Steuerung der Navigation Mobile
* 
* ******************************************************************************************/

; (function ($, window, document, undefined) {
    //Plugin Name
    var pluginName = 'navigationMobile';

    //Defaults
    var defaults = {
        slideAnimationDuration: 200,
        //pageSelector: '>div',
        shadowWidth: 10
    };

    // Konstruktur
    function NavigationMobile(el, options) {
        this.element = el instanceof jQuery ? el : $(el);
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.init();
    }

    // Methoden und Eigenschaften
    $.extend(NavigationMobile.prototype, {
        // Root jquery element
        element: null,

        // Optionen
        options: null,

        // Komponente initialiseren
        init: function () {
            var that = this;
            var ul = this.element.find('>ul');
            //console.log(this.element, ul);

            // CSS Klasse nav-menu setzen
            that.addMenuCls(ul);

            // SPAN für Menu Icon erstellen
            that.element.find('.nav-menu').prepend('<span class="menu-arrow"></span>');

            // Event Listener Close Button
            that.element.find('.btn-close').on('click', function (event) {
                event.preventDefault();
                if (that.element.hasClass('open'))
                    that.hideMobileMenu();
            });

            // Event Listener Trigger -> Menu öffnen
            $('a[href="#' + that.element.attr('id') + '"]').on('click', function (event) {
                if (!that.element.hasClass('open')) {
                    event.preventDefault();
                    event.stopPropagation();
                    that.showMobileMenu();
                }
            });

            // Event Listener Transition Ende
            that.element.on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function (e) {
                var html = $('html');

                if (html.hasClass('m-nav-opening')) {
                    html.removeClass('m-nav-opening');
                    html.addClass('m-nav-open');
                }

                if (html.hasClass('m-nav-closing')) {
                    html.removeClass('m-nav-closing');
                    html.removeClass('m-nav-open');
                    that.element.hide();
                    if (that.block) {
                        that.block.remove();
                        that.block = null;
                    }
                }
            });

            // Event Listener Mouse Click Submenu
            that.element.find('li.nav-menu > .menu-arrow').on('click', function (event) {
                var $this = $(this);
                var li = $this.closest('li.nav-menu');
                var ul = li.find('>ul');
                if (li.hasClass('open')) {
                    ul.slideUp(that.options.slideAnimationDuration, function () { li.removeClass('open'); });
                } else {
                    ul.slideDown(that.options.slideAnimationDuration, function () { li.addClass('open'); });
                }
            });

            // Event Listener Mouse Click Submenu Link
            that.element.find('li.nav-menu > a[href="#"]').on('click', function (event) {
                event.preventDefault();
                $(this).parent().find('>.menu-arrow').trigger('click');
            });
        },

        // Mobile Menu anzeigen
        showMobileMenu: function () {
            var that = this;

            that.element.css('right', - that.element.width() - that.options.shadowWidth + 'px');
            that.element.show();
            $('html').addClass('m-nav-opening');
            that.element.addClass('open');
            that.block = $('<div class="m-nav-block"></div>"').appendTo($('body'));

            that.block.on('touchmove touchend click', function (e) {
                e.preventDefault();
                that.hideMobileMenu();
            });

            setTimeout(function () {
                that.element.css('right', 0);
            }, 10);
        },

        // Mobile Menu schliessen
        hideMobileMenu: function () {
            var that = this;
            $('html').addClass('m-nav-closing');
            that.element.removeClass('open');

            setTimeout(function () {
                that.element.css('right', - that.element.width() - that.options.shadowWidth + 'px');
            }, 10);
        },

        //pageWrap: function () {
        //	var wrap = $('body > .mobile-navigation-page-wrap');
        //	if (!wrap.length) {
        //		$(this.options.pageSelector, $('body')).wrapAll('<div class="mobile-navigation-page-wrap" />');
        //		wrap = $('body > .mobile-navigation-page-wrap');
        //	}

        //	if (!this.element.parent().is('body')) {
        //		console.log('move');
        //		this.element.appendTo($('body'));
        //	}
        //},

        // CSS Klasse für Menu-Button hinzufügen
        addMenuCls: function ($ul) {
            var that = this;
            var arrLi = $ul.find('> li');
            arrLi.each(function (idx, li) {
                var $li = $(li);
                var $subUl = $li.find('> ul');

                if ($subUl.length > 0) {
                    $li.addClass('nav-menu');
                    that.addMenuCls($subUl);

                    //Nodes mit Klasse active öffnen
                    if ($li.hasClass('active')) {
                        $li.addClass('open');
                    }
                }
            });
        }
    });

    // Registration jQuery Plugin
    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, pluginName)) {
                $.data(this, pluginName,
                    new NavigationMobile(this, options));
            }
        });
    };

})(jQuery, window, document);