import React, { Component, Fragment, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';
import { i18n } from '@lingui/core';
import { Trans, t } from "@lingui/macro";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationTriangle, faQuestionCircle, faChevronRight, faShoppingBasket } from '@fortawesome/pro-solid-svg-icons';

const ACTION = {
    SHOW: 0
};

const eventManager = {
    list: new Map(),

    on(event, callback) {
        this.list.has(event) || this.list.set(event, []);
        this.list.get(event).push(callback);
        return this;
    },

    off(event) {
        this.list.delete(event);
        return this;
    },

    /*
     * Enqueue the event at the end of the call stack
     * Without setTimemout the code above will not work
     */
    emit(event, ...args) {
        this.list.has(event) &&
            this.list.get(event).forEach(callback =>
                setTimeout(() => {
                    callback(...args);
                }, 0)
            );
    }
};




const modal = {
    show: function(cfg) {
        eventManager.emit(ACTION.SHOW, cfg);
    },

    confirm: function (title, message, dialogType, closeCallback, buttonYesText, buttonNoText) {
        eventManager.emit(ACTION.SHOW, {
            component: ConfirmModal,
            props: {
                title,
                message,
                dialogType,
                buttonYesText,
                buttonNoText
            },
            closeCallback
        });
    },

    basketItemAdded: function (title, message, dialogType, closeCallback) {
        eventManager.emit(ACTION.SHOW, {
            component: ItemAddedModal,
            props: {
                title,
                message,
                dialogType
            },
            closeCallback
        });
    },

    DIALOG_TYPES: {
        INFO: 0,
        WARNING: 1,
        DANGER: 2,
        QUESTION: 3
    },

    alert: function (title, message, dialogType, closeCallback, props) {
        eventManager.emit(ACTION.SHOW, {
            component: AlertModal,
            props: {
                title,
                message,
                dialogType,
                ...props
            },
            closeCallback
        });
    }
};


const getDialogTypeSettings = (dialogType) => {

    let icon = null;
    const style = {};

    if (dialogType === modal.DIALOG_TYPES.INFO) {
        icon = <FontAwesomeIcon icon={faInfoCircle} size="lg" className="mr-2" />;
        style.backgroundColor = '#428bca';
        style.color = 'white';
    } else if (dialogType === modal.DIALOG_TYPES.WARNING) {
        icon = <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mr-2" />;
        style.backgroundColor = '#f0ad4e';
        style.color = 'white';
    } else if (dialogType === modal.DIALOG_TYPES.DANGER) {
        icon = <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="mr-2" />;
        style.backgroundColor = '#d9534f';
        style.color = 'white';
    } else if (dialogType === modal.DIALOG_TYPES.QUESTION) {
        icon = <FontAwesomeIcon icon={faQuestionCircle} size="lg" className="mr-2" />;
        style.backgroundColor = '#428bca';
        style.color = 'white';
    }

    return {
        icon,
        style
    };
};

const ItemAddedModal = (props) => {
    const [isOpen, setIsOpen] = useState(true);

    function toggle(returnValue) {
        if (isOpen) {
            props.onClose(returnValue);
        }
        setIsOpen(!isOpen);
    }

    const dialogType = getDialogTypeSettings(props.dialogType);

    return (
        <Modal isOpen={isOpen} toggle={() => toggle(false)} backdrop="static">
            <ModalHeader toggle={() => toggle(false)} style={dialogType.style}>{dialogType.icon}{props.title}</ModalHeader>
            <ModalBody>
                {props.message}
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button color="secondary" onClick={() => toggle(false)}>
                    <FontAwesomeIcon icon={faChevronRight} fixedWidth className="mr-2" /><Trans id="continueShopping">Weiter einkaufen</Trans>
                </Button>
                <Button color="primary" onClick={() => toggle(true)}>
                    <FontAwesomeIcon icon={faShoppingBasket} fixedWidth className="mr-2" /><Trans id="continueToBasket">Zum Warenkorb</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    );
};


const ConfirmModal = (props) => {
    const [isOpen, setIsOpen] = useState(true);

    function toggle(returnValue) {
        if (isOpen) {
            props.onClose(returnValue);
        }
        setIsOpen(!isOpen);
    }

    const dialogType = getDialogTypeSettings(props.dialogType);

    return (
        <Modal isOpen={isOpen} toggle={() => toggle(false)} backdrop="static">
            <ModalHeader toggle={() => toggle(false)} style={dialogType.style}>{dialogType.icon}{props.title}</ModalHeader>
            <ModalBody>
                {props.message}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => toggle(false)}>
                    {props.buttonNoText ? props.buttonNoText : <Trans id="no">Nein</Trans>}
                </Button>
                <Button color="primary" onClick={() => toggle(true)}>
                    {props.buttonYesText ? props.buttonYesText : <Trans id="yes">Ja</Trans>}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const AlertModal = (props) => {
    const [isOpen, setIsOpen] = useState(true);

    function toggle() {
        if (isOpen) {
            props.onClose();
        }
        setIsOpen(!isOpen);
    }

    const dialogType = getDialogTypeSettings(props.dialogType);

    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop="static" size={props.size}>
            <ModalHeader toggle={toggle} style={dialogType.style}>{dialogType.icon}{props.title}</ModalHeader>
            <ModalBody className={props.bodyClassName}>
                {props.message}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}><Trans id="close">Schliessen</Trans></Button>
            </ModalFooter>
        </Modal>
    );
};

class ModalContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modals: []
        };
    }

    componentDidMount() {
        eventManager
            .on(ACTION.SHOW, (...args) => this.openModal(...args));
    }

    componentWillUnmount() {
        eventManager
            .off(ACTION.SHOW);
    }

    openModal(cfg) {
        const modals = [...this.state.modals];
        modals.push(cfg);
        this.setState({ modals });
    }


    onModalClose(returnValue, idx) {
        const modal = this.state.modals[idx];
        const modals = [...this.state.modals];
        modals.splice(idx, 1);
        this.setState({ modals });

        if (typeof modal.closeCallback === 'function') {
            modal.closeCallback(returnValue);
        }
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.modals.map((modal, idx) => {
                        const ModalCmp = modal.component;
                        return <ModalCmp key={idx} onClose={(returnValue) => this.onModalClose(returnValue, idx)} {...modal.props} />;
                    })
                }
            </Fragment>
        );
    }
}

export { ModalContainer, modal };