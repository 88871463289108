import BootstrapModal from '../Scripts/bootstrap-modal';
import * as Ladda from 'ladda';
import Noty from 'noty';
import 'jquery-file-download';
import Cookies from 'js-cookie';
import Sortable from 'sortablejs';

(function ($, window) {

    /***********************************
    * Handler für Merkliste add buttons
    ************************************/
    $(document).on('click', 'a[data-add-to-doc-library="true"],button[data-add-to-doc-library="true"]', function (e) {
        e.preventDefault();
        var $this = $(this);

        
        //auswahl merkliste anzeigen
        $.ajax({
            url: $this.data('library-select-url'),
            type: "GET",
            success: function (data, status, xhr) {
                var lastSelectedLibrary = Cookies.get('selectedDocLibrary') || 0;
                var drp = $('<select/>').css('width', '100%').addClass('form-control');
                drp.append($("<option>").attr('value', '0').text('- '  + data.newLibrary + ' -'));
                $(data.libraries).each(function () {
                    var option = $("<option>").attr('value', this.id).text(this.name);
                    if (this.id == lastSelectedLibrary)
                        option.attr('SELECTED', 'YES');
                    drp.append(option);
                });

                var txtNewLibrary = $('<input />').attr('type', 'text').attr('placeholder', data.libraryName).addClass('form-control').css('margin-top', '10px');
                if (lastSelectedLibrary != 0)
                    txtNewLibrary.css('display', 'none');

                drp.on('change', function (e) {
                    txtNewLibrary.toggle($(this).val() == 0);
                });

                var body = $('<div />').append(drp).append(txtNewLibrary);

                new BootstrapModal({
                    title: data.title,
                    message: body,
                    buttons: [
                        {
                            label: data.btnOk,
                            cssClass: 'btn btn-primary',
                            action: function (dialog, e) {
                                var l = Ladda.create(e.target);
                                l.start();
                                var libraryId = dialog.$modalBody.find('select').val();
                                var paramNewLibraryName = drp.val() == 0 && txtNewLibrary.val() ? '&newLibraryName=' + encodeURIComponent(txtNewLibrary.val()) : ''

                                Cookies.set('selectedDocLibrary', libraryId, { expires: 365, path: '/' });
                                $.ajax({
                                    url: $this.data('add-url') + ($this.data('add-url').indexOf('?') > -1 ? '&' : '?') + 'docLibraryId=' + libraryId + paramNewLibraryName,
                                    type: "GET",
                                    success: function (data, status, xhr) {
                                        if (data.success) {
                                            if (data.message) {
                                                new Noty({ text: data.message }).show();
                                                window.myRecordBadge(data.itemCount);
                                                if ($('#doclibrary-content').length) {
                                                    location.reload();
                                                }
                                            }
                                        }
                                    },
                                    complete: function () {
                                        dialog.close();
                                    }
                                });
                            }
                        },
                        {
                            label: data.btnCancel,
                            cssClass: 'btn btn-secondary',
                            action: function (dialog, e) {
                                dialog.close();
                            }
                        }
                    ]
                });
            }
        });
    });

    /**********************************************
    * Klasse für Funktionen der Merklisten-Seite
    ***********************************************/
    var docLibraryPage = function (options) {
        this.options = $.extend({}, {
            baseUrl: window.location.pathname,
            baseUrlShare: '/de/share/',
            resources: {
                save: 'Speichern',
                cancel: 'Abbrechen',
                close: 'Schliessen',
                add: 'Hinzufügen',
                yes: 'Ja',
                no: 'Nein',
                share: 'Teilen',
                docLibRename: 'Merkliste umbenennen',
                docLibAdd: 'Neue Merkliste hinzufügen',
                docLibDelete: 'Merkliste löschen',
                deleteQuestion: 'Soll diese Merkliste wirklich gelöscht werden?',
                pdfExport: 'PDF Export',
                preparingDownload: 'Das Dokument wird aufbereitet...',
                downloadError: 'Bei der Aufbereitung des Dokuments ist ein Fehler aufgetreten.',
                zipExport: 'Download als ZIP'
            }
        }, options);
        this.initialize();
    };

    $.extend(docLibraryPage.prototype, {

        initialize: function () {
            var that = this;

            //create Sort Drag Handle
            $('.doclibrary-library .listview-wrapper .listview-item:not(.not-sortable)').each(function (idx, el) {
                var $el = $(el), handle = $el.find('.drag-handle');
                if (!handle.length) {
                    $('<i class="fa fa-arrows drag-handle"></i>').appendTo($el);
                }
            });

            //Sortierung
            var downloadPdfItem;
            $('.doclibrary-library .listview-wrapper').each(function (i, e) {
                Sortable.create(e, {
                    animation: 500,
                    ghostClass: 'sortable-ghost',
                    draggable: '.listview-item',
                    //filter: '.not-sortable',
                    handle: '.drag-handle',

                    onStart: function(evt) {
                        downloadPdfItem = $(evt.item).closest('.doclibrary-library').find('.listview-item-pdfdownload').detach();
                    },

                    onEnd: function (evt) {
                        downloadPdfItem.appendTo($(evt.item).closest('.doclibrary-library').find('.listview-wrapper'));
                        var libraryId = $(evt.item).closest('.doclibrary-library').data('doc-library-id');
                        var entryIds = [];
                        $(evt.item).closest('.listview-wrapper').find('div.listview-item[data-doc-library-entry-id]').each(function(idx,el) {
                            entryIds.push($(el).data('doc-library-entry-id'));
                        });
                        if (libraryId && entryIds && entryIds.length && evt.newIndex != evt.oldIndex) {
                            $.ajax({
                                url: that.options.baseUrl + '/ChangeSort/' + libraryId,
                                type: "POST",
                                data: {
                                    arrEntryId: entryIds
                                }
                                //success: function (data, status, xhr) {
                                //}
                            });
                        }
                    }
                });
            });

            //öffnen/schliessen der merklisten
            $(document).on('click', '.doclibrary-actions button.toggle-library', function (e) {
                e.preventDefault();
                $(this).closest('.doclibrary-library').toggleClass('open');
            });

            //Merklistenbearbeitung
            $(document).on('click', '.doclibrary-actions .dropdown-menu a', function (e) {
                e.preventDefault();
                var cmd = $(this).data('command');
                var libraryId = $(this).closest('.doclibrary-library').data('doc-library-id');
                if (cmd && libraryId) {
                    switch (cmd)
                    {
                        case 'rename':
                            that.renameLibrary(libraryId);
                            break;
                        case 'delete':
                            that.deleteLibrary(libraryId);
                            break;
                        case 'duplicate':
                            that.duplicateLibrary(libraryId);
                            break;
                        case 'pdf':
                            that.exportPdf(libraryId);
                            break;
                        case 'zip':
                            that.exportZip(libraryId);
                            break;
                        case 'share':
                            record.contentShareDialog(
                                that.options.baseUrlShare + '/sharelibrary/' + libraryId,
                                that.options.baseUrlShare + '/sharelibrarylink/' + libraryId
                            );
                            break;
                    }
                }
            });

            //Merklisten-Eintrag löschen
            $(document).on('click', '.doclibrary-library a.doc-library-remove', function (e) {
                e.preventDefault();
                var id = $(this).closest('.listview-item').data('doc-library-entry-id');
                that.removeEntry($(this).closest('.listview-item'), id);
            });

            //Merkliste hinzufügen
            $(document).on('click', 'button.btn-add-doclibrary', function (e) {
                e.preventDefault();
                that.addLibrary();
            });

            //Download List-Item click event
            $(document).on('click', '.btn-pdf-download-doclibrary', function (e) {
                e.preventDefault();
                var libraryId = $(this).closest('div[data-doc-library-id]').data('doc-library-id');
                if (libraryId) {
                    if (window.ga)
                        window.ga('send', 'event', 'my.record', 'Save my.record list as PDF', 'Download PDF');
                    that.exportPdf(libraryId);
                }
            });
        },

        //Eintrag löschen
        removeEntry: function (element, id) {
            if (element.length == 1) {
                $.ajax({
                    url: this.options.baseUrl + '/DeleteEntry/' + id,
                    type: "GET",
                    success: function (data, status, xhr) {
                        if (data.success) {
                            element.remove();
                            myRecordBadge(data.itemCount);
                        }
                    }
                });
            }
        },

        //Merkliste umbenennen
        renameLibrary: function (libraryId) {
            var that = this;
            new BootstrapModal({
                title: that.options.resources.docLibRename,
                nl2br: false,
                message: $('<div><div style="text-align:center;margin:10px 0;"><i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="vertical-align:middle;"></i></div></div>').load(this.options.baseUrl + '/RenameLibrary/' + libraryId),
                buttons: [
                    {
                        label: that.options.resources.save,
                        cssClass: 'btn btn-primary',
                        action: function (dialog, e) {
                            var btnSave = this;
                            var $form = dialog.$modalBody.find('form');
                            if ($form.length == 1) {
                                var l = Ladda.create(this);
                                l.start();
                                var newName = $form.find('input[name="Name"]').val();


                                $.ajax({
                                    url: $form[0].action,
                                    type: "POST",
                                    data: $form.serializeArray(),
                                    success: function (data, status, xhr) {
                                        dialog.setMessage(data);
                                        if (xhr.getResponseHeader('x-save-success') == 'true') {
                                            $('.doclibrary-library[data-doc-library-id="' + libraryId + '"] .doclibrary-actions span.library-name').html(newName);
                                            dialog.close();
                                        }
                                    },
                                    complete: function () {
                                        l.stop();
                                    }
                                });
                            }
                        }
                    },
                    {
                        label: that.options.resources.cancel,
                        cssClass: 'btn btn-secondary',
                        action: function (dialog) {
                            dialog.close();
                        }
                    }
                ]
            });
        },

        deleteLibrary: function (libraryId) {
            var that = this;
            BootstrapModal.confirm({
                nl2br: false,
                title: that.options.resources.docLibDelete,
                message: that.options.resources.deleteQuestion,
                btnNoLabel: that.options.resources.no,
                btnYesLabel: that.options.resources.yes,
                callback: function (result) {
                    if (result) {
                        $.ajax({
                            url: that.options.baseUrl + '/DeleteLibrary/' + libraryId,
                            type: "GET",
                            success: function (data, status, xhr) {
                                if (data.success) {
                                    $('.doclibrary-library[data-doc-library-id="' + libraryId + '"]').remove();
                                    myRecordBadge(data.itemCount);
                                }
                            }
                        });
                    }
                }
            });
        },

        duplicateLibrary: function (libraryId) {
            $.ajax({
                url: this.options.baseUrl + '/DuplicateLibrary/' + libraryId,
                type: "GET",
                success: function (data, status, xhr) {
                    if (data.success) {
                        window.location.reload(true);
                    }
                }
            });
        },

        addLibrary: function() {
            var that = this;
            new BootstrapModal({
                title: that.options.resources.docLibAdd,
                nl2br: false,
                message: $('<div><div style="text-align:center;margin:10px 0;"><i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="vertical-align:middle;"></i></div></div>').load(this.options.baseUrl + '/AddLibrary'),
                buttons: [
                    {
                        label: that.options.resources.add,
                        cssClass: 'btn btn-primary',
                        action: function (dialog) {
                            var $form = dialog.$modalBody.find('form');
                            if ($form.length == 1) {
                                var l = Ladda.create(this);
                                l.start();

                                $.ajax({
                                    url: $form[0].action,
                                    type: "POST",
                                    data: $form.serializeArray(),
                                    success: function (data, status, xhr) {
                                        dialog.setMessage(data);
                                        if (xhr.getResponseHeader('x-save-success') == 'true') {
                                            window.location.reload();
                                        }
                                    },
                                    complete: function () {
                                        l.stop();
                                    }
                                });
                            }
                        }
                    },
                    {
                        label: that.options.resources.cancel,
                        action: function (dialog) {
                            dialog.close();
                        }
                    }
                ]
            });
        },

        exportPdf: function (libraryId) {
            var that = this;
            Cookies.set("fileDownload", null, { path: '/' });

            var dialog = new BootstrapModal({
                title: this.options.resources.pdfExport,
                nl2br: false,
                message: '<div style="margin:20px 0;text-align:center;"><i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="vertical-align:middle;margin-right:20px;"></i> ' + this.options.resources.preparingDownload + '</div>',
                dialog: true
            });

            $.fileDownload(this.options.baseUrl + '/PdfExport/' + libraryId, {
                successCallback: function (url) {
                    dialog.close();
                },
                failCallback: function (responseHtml, url, error) {
                    dialog.close();
                    BootstrapModal.alert({
                        title: that.options.resources.pdfExport,
                        message: that.options.resources.downloadError,
                        alertType: 'warning'
                    });
                }
            });
        },

        exportZip: function (libraryId) {
            var that = this;
            Cookies.set("fileDownload", null, { path: '/' });

            var dialog = new BootstrapModal({
                nl2br: false,
                title: this.options.resources.zipExport,
                message: '<div style="margin:20px 0;text-align:center;"><i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="vertical-align:middle;margin-right:20px;"></i> ' + this.options.resources.preparingDownload + '</div>',
                dialog: true
            });

            $.fileDownload(this.options.baseUrl + '/ZipExport/' + libraryId, {
                successCallback: function (url) {
                    dialog.close();
                },
                failCallback: function (responseHtml, url, error) {
                    dialog.close();
                    BootstrapModal.alert({
                        title: that.options.resources.zipExport,
                        message: that.options.resources.downloadError,
                        alertType: 'warning'
                    });
                }
            });
        }


    });

    //als JQuery Funktion registrieren
    $.fn.docLibraryPage = function (options) {
        return this.each(function () {
            var dlp = new docLibraryPage(options);
        });
    };


})(jQuery, window);
