import BootstrapModal from '../Scripts/bootstrap-modal';
import svgPanZoom, { destroy } from 'svg-pan-zoom';
import Hammer from 'hammerjs';

//Disable Caching for AJAX Requests
$.ajaxSetup({ cache: false });

/*************************************************
 * Mobile Navigation initialisieren
 *************************************************/
$(function () {
    $("#mobile-menu").mmenu({
        extensions: ['effect-slide-menu', 'pageshadow'],
        counters: false,
        isMenu: false,
        navbar: {
            title: 'Navigation'
        },
        navbars: [
            {
                position: 'top',
                content: [
                    'prev',
                    'title',
                    'close'
                ]
            }
        ]
    });
});

/******************************************************************
 * Funktion zur Detektion der aktuellen Bootstrap Device Klasse
 ******************************************************************/
function detectBootstrapEnv() {
    var envs = ['xs', 'sm', 'md', 'lg', 'xl'];

    var $elDetectEnv = $('#bs-detect-device-class');
    if (!$elDetectEnv) {
        $elDetectEnv = $('<div class="device-xs d-block d-sm-none"></div><div class="device-sm d-none d-sm-block d-md-none"></div><div class="device-md d-none d-md-block d-lg-none"></div><div class="device-lg d-none d-lg-block d-xl-none"></div><div class="device-xl d-none d-xl-block"></div>');
        $elDetectEnv.appendTo($('body'));
    }

    for (var i = envs.length - 1; i >= 0; i--) {
        var env = envs[i];
        if ($('.device-' + env).is(':visible'))
            return env;
    };
}

window.detectBootstrapEnv = detectBootstrapEnv;

/******************************************************************************
* Badge Anzeige für my.records Links initialisieren
******************************************************************************/
window.myRecordBadge = function (count, url) {
    if (url) {
        $('a[href="' + url + '"]').each(function (idx, el) {
            var lnk = $(el);
            var badge = lnk.children('.badge.badge-myrecord');
            if (badge.length == 0) {
                var badge = $('<span class="badge badge-myrecord">' + count + '</span>').appendTo(lnk);
                if (count <= 0) {
                    badge.hide();
                }
            }
        });
    } else {
        var badge = $('span.badge.badge-myrecord');
        if (count <= 0) {
            badge.hide();
            badge.html('');
        } else {
            badge.html(count).show().fadeTo(300, 0.3).fadeTo(200, 1);
        }
    }
};

/******************************************************************************
* Inhalts-DIV auf die sichtbare höhe skalieren, falls kleiner (nur MD und LG)
******************************************************************************/
$(function () {
    var bsEnv = detectBootstrapEnv();
    if (bsEnv == 'sm' || bsEnv == 'md' || bsEnv == 'lg') {
        var $div = $('.content-maximize-height:first');
        if ($div.length) {
            var topY = $div.offset().top, topYFooter = $('#footer').offset().top, h = $div.outerHeight(), space = topYFooter - topY - 20;
            //console.log('topY:', topY, 'topYFooter:', topYFooter, 'h:', h, 'space:', space);

            if (h < space) {
                $div.css('min-height', space);
            }
        }
    }
});

/******************************************************************************
* Inhalts-DIV am unteren Seitenrand ausrichten (nur MD und LG)
******************************************************************************/
$(function () {
    var bsEnv = detectBootstrapEnv();
    if (bsEnv == 'sm' || bsEnv == 'md' || bsEnv == 'lg') {
        var $div = $('.content-align-bottom:first');
        if ($div.length) {
            var bottomY = $div.offset().top + $div.outerHeight(), bottomYTarget = $('#footer').offset().top - (bsEnv == 'sm' ? 15 : 20);
            //console.log('bottomY:', bottomY, 'topYTarget:', bottomYTarget);

            if (bottomY < bottomYTarget) {
                $div.css('margin-top', bottomYTarget - bottomY);
            }
        }
    }
});

$(function () {

    // Klick Hamburger
    $('.subnavbtn').click(function (e) {
        e.preventDefault();
        $('#swrapper').toggleClass("toggled");
    });

    /* Subnavigation ein- und ausfahren */
    updateSubNavLinksScroll();
    $(window).resize(function () {
        updateSubNavLinksScroll();
    });
    function updateSubNavLinksScroll() {
        $('#accordionMenu').css({ "height": "" });
        var wHeight = $(window).height() - 190;
        var nHeight = $('#accordionMenu').outerHeight(true);
        if (nHeight > wHeight) {
            $('#accordionMenu').css({ "height": wHeight + "px" });
        }
    }

    /* Level 2 anzeigen beim Hover */
    $(".panel-heading").parent('.panel').hover(
        function () {
            $(this).children('.collapse').collapse('show');
        }, function () {
            $(this).children('.collapse').collapse('hide');
        }
    );

    /******************************************************************************
     * Footer unten an Content setzen
     ******************************************************************************/
    var windowheight = $(window).height();
    var container = $('#container')
    var contentheight = container.height();
    if (!container.hasClass('no-fixed-bottom')) {
        if (windowheight > contentheight) {
            var currContainerClass = $('#container').attr("class");
            $('#container').addClass(currContainerClass + ' pbottom');
            var currFooterClass = $('#footer').attr("class");
            $('#footer').addClass(currFooterClass + ' fixed-bottom');
        }
    }

    /******************************************************************************
     * Scroll to Bottom/Top Link
     ******************************************************************************/
    var documentheight = $(document).height();
    if (documentheight <= windowheight)
        $('#footer .bottom-link').hide();

    /* Footer Button */
    $('#footer .top-link').hide();
    $(document).scroll(function (e) {
        var scroll = $(window).scrollTop();
        $('#footer .top-link').toggle(scroll > 0);
    });

    $('#btn-scroll-to-top').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
    });

    /******************************************************************************
     * Image Hover
     ******************************************************************************/
    var sourceSwap = function () {
        var $this = $(this);
        var newSource = $this.data('alt-src');
        $this.data('alt-src', $this.attr('src'));
        $this.attr('src', newSource);
    };

    $('img[data-alt-src]').each(function () {
        new Image().src = $(this).data('alt-src');
    }).hover(sourceSwap, sourceSwap);

    /******************************************************************************
     * Background bei geöffnetem Modal verschieben
     ******************************************************************************/
    //var oldSSB = BootstrapDialog.BootstrapDialogModal.prototype.setScrollbar;
    //BootstrapDialog.BootstrapDialogModal.prototype.setScrollbar = function () {
    //    oldSSB.apply(this);
    //    if (this.bodyIsOverflowing && this.scrollbarWidth) {
    //        $('.background-container').css('right', this.scrollbarWidth);
    //    }
    //}

    //var oldRSB = BootstrapDialog.BootstrapDialogModal.prototype.resetScrollbar;
    //BootstrapDialog.BootstrapDialogModal.prototype.resetScrollbar = function () {
    //    oldRSB.apply(this);
    //    $('.background-container').css('right', '0');
    //}

    //var oldSSB2 = $.fn.modal.Constructor.prototype.setScrollbar;
    //$.fn.modal.Constructor.prototype.setScrollbar = function () {
    //    oldSSB2.apply(this);
    //    if (this.bodyIsOverflowing && this.scrollbarWidth) {
    //        $('.background-container').css('right', this.scrollbarWidth);
    //    }
    //}

    //var oldRSB2 = $.fn.modal.Constructor.prototype.resetScrollbar;
    //$.fn.modal.Constructor.prototype.resetScrollbar = function () {
    //    oldRSB2.apply(this);
    //    $('.background-container').css('right', '0');
    //}

    /******************************************************************************
    * Google Analytics: Track Event
    ******************************************************************************/
    $(document).on('click', 'a[data-ga-event-category]', function () {
        var $this = $(this);
        var category = $this.data('ga-event-category');
        var action = $this.data('ga-event-action');
        var label = $this.data('ga-event-label');
        if (window.ga && category && action) {
            window.ga('send', 'event', category, action, label);
            //console.log('event tracked...');
        }
    });

    /******************************************************************************
    * BootstrapDialog öffnen
    ******************************************************************************/
    $(document).on('click', 'a[data-bsdialog="alert"]', function (e) {
        e.preventDefault();
        var $this = $(this);

        new BootstrapModal({
            title: $this.data('title'),
            message: $this.data('message'),
            buttons: [{
                label: 'OK',
                action: function (dialog, e) {
                    dialog.close();
                }
            }]
        });
    });

    $(document).on('click', 'a[data-bsdialog="document-no-permission"]', function (e) {
        e.preventDefault();
        var $this = $(this);

        var buttons = [];
        if ($this.data('label-login')) {
            buttons.push({
                label: $this.data('label-login'),
                action: function (dialog, e) {
                    window.location = $this.data('href-login') + '?returnUrl=' + encodeURI(window.location.pathname + window.location.search);
                }
            });
        }
        if ($this.data('label-register')) {
            buttons.push(
                {
                    label: $this.data('label-register'),
                    action: function (dialog, e) {
                        window.location = $this.data('href-register');
                    }
                }
            );
        }

        buttons.push(
            {
                label: $this.data('label-close'),
                action: function (dialog, e) {
                    dialog.close();
                }
            }
        );

        new BootstrapModal({
            title: $this.data('title'),
            message: $this.data('message'),
            buttons: buttons
        });

        //BootstrapModal.show({
        //    title: $this.data('title'),
        //    message: $this.data('message'),
        //    buttons: buttons
        //});
    });

    /******************************************************************************
    * Accordion anchor
    ******************************************************************************/
    $('.AccordionLink').click(function () {
        var element = $(this).attr('href');
        var closestElement = $(element).closest("[aria-labelledby]");
        closestElement.collapse('show');
        var scrollVal = closestElement.offset().top - 170;
        if (scrollVal < 0) {
            scrollVal = 0;
        }
        $('html, body').animate({ scrollTop: scrollVal }, 'slow');
    });

    /******************************************************************************
    * Sticky Navigation
    ******************************************************************************/
    stickyMarginTopHandler();
    $('.sticky-container').css('right', '-' + ($('.sticky-container').width() - 40) + 'px');
    $('.sticky').hover(function () {
        var moveLeft = $(this).children().width();
        if ($(this).children('.multiple').length) {
            $($(this).children('.multiple')).each(function () {
                if ($(this).width() > moveLeft) {
                    moveLeft = $(this).width();
                }
            });
        }
        $(this).children().animate({ left: '-' + (moveLeft - 25) + 'px' }, 500);
    }, function () {
        $(this).children().animate({ left: '0px' }, 500);
    });

    $(window).resize(function () {
        stickyMarginTopHandler();
    });
    function stickyMarginTopHandler() {
        var stickyMarginTop = 279;
        if ($('.recBlue').length) {
            stickyMarginTop = $('.recBlue').offset().top;
            stickyMarginTop += $('.recBlue').outerHeight(true);
            stickyMarginTop += 25;
        }
        $('.sticky-container').css('top', stickyMarginTop + 'px');
    }

    $("#showMobilePhoneLayer").click(function () {
        $(".phoneLayer").toggle("slow");
    });

    $(".phoneLayer").click(function () {
        $(".phoneLayer").hide();
    });

    /******************************************************************************
    * Dokumente Modal Logik
    ******************************************************************************/
    $(document).on('click', '.openVideoModal', function () {
        var videoId = $(this).attr('video-id');
        var videoHash = $(this).attr('video-hash');
        $("#recordVideo").removeAttr('src');
        $("#recordVideo").attr('src', 'https://player.vimeo.com/video/' + videoId + '?h=' + videoHash + '&badge=0' + '?autoplay=1&amp;modestbranding=1&amp;showinfo=0');
        $('#recordVimeoVideo').modal('show');
    });

    $('#stopVimeoVideo').click(function () {
        $("#recordVideo").removeAttr('src');
    });

    $(document).on('click', '.openPdfModal', function () {
        var pdfUrl = $(this).attr('pdf-url');
        $("#previewPdfUrl").removeAttr('src');
        $("#previewPdfUrl").attr('src', pdfUrl);
        $('#recordPreviewPdf').modal('show');
    });

    $(document).on('click', '.openImageModal', function () {
        var imageUrl = $(this).attr('image-url');
        $("#previewImageUrl").removeAttr('src');
        $("#previewImageUrl").attr('src', imageUrl);
        $('#recordPreviewImage').modal('show');
    });

    if ($("#previewImageUrl").length) {
        $("#previewImageUrl").load(function () {
            var imgWidth = $(this).prop('naturalWidth');
            if (imgWidth < 1000) {
                $("#imgWrapper").css("maxWidth", imgWidth + "px");
            }
        });
    }

    /******************************************************************************
    * Stop Inhaltsmodul-Video
    ******************************************************************************/
    $(document).on('click', '.stopVideo', function () {
        var componentId = $(this).attr('component-id');
        var iframeId = "#recordVideo-" + componentId;
        var videoLink = $(iframeId).attr('src');
        $(iframeId).removeAttr('src');
        $(iframeId).attr('src', videoLink);
    });

    /******************************************************************************
    * Listenansichten umschalten (Liste oder Boxen)
    ******************************************************************************/
    /*
    if ($('.listview-wrapper').hasClass('listview-view-box'))
        $('.set-listview.listview-box').addClass('selected');
    else
        $('.set-listview.listview-list').addClass('selected');

    $('.set-listview').on('click', function () {
        var $this = $(this);
        var mode = $this.hasClass('listview-list') ? 'list' : 'box';
        var otherMode = mode == 'box' ? 'list' : 'box';
        console.log(mode);
        $('.listview-wrapper').removeClass('listview-view-' + otherMode);
        $('.listview-wrapper').addClass('listview-view-' + mode);
        $('.set-listview').removeClass('selected');
        $this.addClass('selected');
        $.cookie('listview-mode', mode, { expires: 365, path: window.location.pathname });
        //$("img").trigger("checkUnveil");
    });
    */

    /******************************************************************************
    * Störungsmeldung Formular: Name und Tel. übertragen
    ******************************************************************************/
    if ($("#IdenticalContact").length) {
        $('#IdenticalContact').change(function () {
            if (this.checked) {
                $('#ResponsiblePersonName').val($('#Name').val());
                $('#ResponsiblePersonPhone').val($('#Phone').val());
            }
            else {
                $('#ResponsiblePersonName').val('');
                $('#ResponsiblePersonPhone').val('');
            }
        });
    }

    /******************************************************************************
    * svgPanZoom
    ******************************************************************************/
    $('#sparePartSwitch').change(function () {
        let zoomActive = $(this).prop('checked');
        if (zoomActive)
            enableZoomFunction();
        else {
            if (window.panZoom)
                window.panZoom.destroy();
        }
    });

    function enableZoomFunction() {

        var eventsHandler;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // Define Events for Mobile Devices

            // Don't use window.onLoad like this in production, because it can only listen to one function.
            eventsHandler = {
                haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel']
                , init: function (options) {
                    var instance = options.instance
                        , initialScale = 1
                        , pannedX = 0
                        , pannedY = 0

                    // Init Hammer
                    // Listen only for pointer and touch events
                    this.hammer = Hammer(options.svgElement, {
                        inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
                    });

                    // Enable pinch
                    this.hammer.get('pinch').set({ enable: true });

                    // Handle double tap
                    this.hammer.on('doubletap', function (ev) {
                        instance.zoomIn()
                    });

                    // Handle pan
                    this.hammer.on('panstart panmove', function (ev) {
                        // On pan start reset panned variables
                        if (ev.type === 'panstart') {
                            pannedX = 0
                            pannedY = 0
                        }

                        // Pan only the difference
                        instance.panBy({ x: ev.deltaX - pannedX, y: ev.deltaY - pannedY })
                        pannedX = ev.deltaX
                        pannedY = ev.deltaY
                    });

                    // Handle pinch
                    this.hammer.on('pinchstart pinchmove', function (ev) {
                        // On pinch start remember initial zoom
                        if (ev.type === 'pinchstart') {
                            initialScale = instance.getZoom()
                            instance.zoomAtPoint(initialScale * ev.scale, { x: ev.center.x, y: ev.center.y })
                        }

                        instance.zoomAtPoint(initialScale * ev.scale, { x: ev.center.x, y: ev.center.y })
                    });

                    // Prevent moving the page on some devices when panning over SVG
                    options.svgElement.addEventListener('touchmove', function (e) { e.preventDefault(); });
                }

                , destroy: function () {
                    this.hammer.destroy()
                }
            }
        }

        document.getElementById('svg-pan-zoom').addEventListener('load', function () {
            bindPanZoom(eventsHandler);
        });
        bindPanZoom(eventsHandler);
    }

    function bindPanZoom(evHandler) {
        let zoomActive = $('#sparePartSwitch').prop('checked');
        if (zoomActive) {
            window.panZoom = svgPanZoom('#svg-pan-zoom', {
                zoomEnabled: true
                , controlIconsEnabled: true
                , fit: 1
                , center: 1
                , customEventsHandler: evHandler
            });
        }
        else {
            if (window.panZoom)
                window.panZoom.destroy();
        }
    }
});

/******************************************************************************
* FPC
******************************************************************************/
import * as Ladda from 'ladda';
import 'jquery-mask-plugin';

window.fpc = {
	ladda: {
		start: function (target) {
			var targetObj = document.querySelector('#' + target);
			if (targetObj) {
				var l = Ladda.create(targetObj);
				l.start();
			}
		},
		stop: function (target) {
			var targetObj = document.querySelector('#' + target);
			if (targetObj) {
				var l = Ladda.create(targetObj);
				l.stop();
			}
		}
	},
	//update: function (url, target, message, type) {
	//	cms.ajax(null, {
	//		url: url,
	//		type: 'GET'
	//	}, {
	//		success: function (data, status, xhr, element, settings, options) {
	//			var htmlResult = $(data);
	//			var htmlTarget = htmlResult.find('#' + target);
	//			var updateMessageContainer = $('#update-message');

	//			updateMessageContainer.removeClass();
	//			updateMessageContainer.addClass('alert alert-' + type);
	//			updateMessageContainer.show();

	//			$('#' + target).replaceWith(htmlTarget);
	//		}
	//	});

	//	if (message) {
	//		$('#update-message').html(message);
	//	}
	//},
	updateMessage: function (message, type) {
		var updateMessageContainer = $('#update-message');

		updateMessageContainer.html(message);
		updateMessageContainer.removeClass();
		updateMessageContainer.addClass('alert alert-' + type);
		updateMessageContainer.show();
	},
	update: function (url, target) {
		$.get(url, function (data) {
			$("#" + target).replaceWith(data);
		});
	},
	show: function (text, title, closable, size, type) {
        var modal = new BootstrapModal({
            title: title,
            message: text
        });

  //      BootstrapDialog.show({
		//	closable: closable || true,
		//	size: size || BootstrapDialog.SIZE_NORMAL,
		//	type: type || BootstrapDialog.TYPE_PRIMARY,
		//	message: text,
		//	title: title
		//});
	},
	init: function () {
		$('[data-mask=dongle]').mask('AAAA', {
			placeholder: 'XXXX',
			onKeyPress: function (value, event, currentField, options) {
				event.currentTarget.value = value.toUpperCase();
			}
		});
	}
	//activateLicensePool: function (address, payment) {
	//	alert(address + ' ' + payment);
	//	if (address) {
	//		var btn = $('#panel-address .change-button');

	//		BootstrapDialog.show({
	//			title: btn.data('dialog-title'),
	//			message: $('<div></div>').html(data),
	//			closable: btn.data('dialog-closable') == null || $this.data('dialog-closable') == true ? true : false,
	//			size: btn.data('dialog-size') != null ? $this.data('dialog-size') : "size-normal"
	//		});
	//	} else if (payment) {
	//		alert('payment dialog');
	//	}
	//}
};

$(function () {
	window.fpc.init();
});

/*************************************************
 * Bootstrap Dialog anzeigen
 *************************************************/
$(function () {
	var loading = $('#content-loading-mask');

	$(document).on('click', 'a[data-dialog-url]', function () {
        var $this = $(this);

		loading.show(10);
		$.get($this.data('dialog-url'), function (data, textStatus, xhr) {
			var contentType = xhr.getResponseHeader("Content-Type") || "text/html"
			if (contentType.indexOf("application/x-javascript") !== -1) {  // jQuery already executes JavaScript for us
				return;
            }

            new BootstrapModal({
                title: $this.data('dialog-title'),
                message: $('<div></div>').html(data),
                modal: $this.data('dialog-closable') !== null && $this.data('dialog-closable') === false,
                dialogClass: $this.data('dialog-class') ? $this.data('dialog-class') : null
                //size: $this.data('dialog-size') != null ? $this.data('dialog-size') : "size-normal"
            });

			//BootstrapDialog.show({
			//	title: $this.data('dialog-title'),
			//	message: $('<div></div>').html(data),
			//	closable: $this.data('dialog-closable') == null || $this.data('dialog-closable') == true ? true : false,
			//	size: $this.data('dialog-size') != null ? $this.data('dialog-size') : "size-normal"
			//	//buttons: [{
			//	//    label: 'schliessen',
			//	//    action: function (dialogItself) {
			//	//        dialogItself.close();
			//	//    }
			//	//}]
			//});
		}).done(function (data) {
			loading.hide(10);
		});
	});
});