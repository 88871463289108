(function ($, window) {
	var cms = window.cms = window.cms || {};

	var data_click = "unobtrusiveAjaxClick",
		data_target = "unobtrusiveAjaxClickTarget",
		data_validation = "unobtrusiveValidation";

	cms.AJAX_DEFAULT_OPTIONS = {
		scope: undefined,
		confirm: undefined,

		loading: undefined,
		loadingDuration: 0,
		loadingDelay: 0,

		update: undefined,
		mode: undefined,

		data: [],
		headers: {},

		destroyKendeui: false,

		prepareRequest: undefined,
		requestAborted: undefined,
		beforeSend: undefined,
		complete: undefined,
		completeInternal: undefined,
		beforeUpdateContent: undefined,
		beforeUpdateContentInternal: undefined,
		success: undefined,
		error: undefined
	}

	cms.ajax = function (element, settings, options) {
		var options = $.extend({}, cms.AJAX_DEFAULT_OPTIONS, options);
		var confirm, loading, method, duration, loadingDelay, loadingDelayTimeout, loadingIsVisible;

		confirm = (element && element.getAttribute("data-ajax-confirm")) || options.confirm;
		if (confirm && !window.confirm(confirm)) {
			return;
		}

		loading = $((element && element.getAttribute("data-ajax-loading")) || options.loading);
		duration = parseInt((element && element.getAttribute("data-ajax-loading-duration")), 10) || options.loadingDuration;
		loadingDelay = parseInt((element && element.getAttribute("data-ajax-loading-delay")), 10) || options.loadingDelay;

		$.extend(settings, {
			url: (element && element.getAttribute("data-ajax-url")) || undefined,
			type: (element && element.getAttribute("data-ajax-method")) || undefined,
			cache: !!(element && element.getAttribute("data-ajax-cache")),

			beforeSend: function (xhr) {
				var result;
				asyncOnBeforeSend(xhr, method);
				result = getFunction((element && element.getAttribute("data-ajax-begin")), ["xhr"]).apply(element, arguments);
				if (result !== false && options.beforeSend) {
					result = options.beforeSend.apply(options.scope || window, [xhr, element, settings, options]);
				}
				if (result !== false) {
					if (loadingDelay > 0) {
						loadingDelayTimeout = setTimeout(function () { loadingIsVisible = true; loading.show(duration); }, loadingDelay);
					} else {
						loading.show(duration);
					}
				}
				return result;
			},
			complete: function (xhr, status) {
				if (loadingDelay > 0 && loadingDelayTimeout) {
					clearTimeout(loadingDelayTimeout);
					if (loadingIsVisible) {
						loading.hide(duration);
					}
				} else {
					loading.hide(duration);
				}
				getFunction((element && element.getAttribute("data-ajax-complete")), ["xhr", "status"]).apply(element, [xhr, status]);
				if (options.complete) {
					options.complete.apply(options.scope || window, [xhr, status, element, settings, options]);
				}

				if (options.completeInternal) {
					options.completeInternal.apply(options.scope || window, [xhr, status, element, settings, options]);
				}
			},
			success: function (data, status, xhr) {
				if (options.beforeUpdateContent) {
					options.beforeUpdateContent.apply(options.scope || window, [data, status, xhr, element, settings, options]);
				}
				if (options.beforeUpdateContentInternal) {
					options.beforeUpdateContentInternal.apply(options.scope || window, [data, status, xhr, element, settings, options]);
				}
				asyncOnSuccess(element, data, xhr.getResponseHeader("Content-Type") || "text/html", options);
				getFunction((element && element.getAttribute("data-ajax-success")), ["data", "status", "xhr"]).apply(element, [data, status, xhr]);
				if (options.success) {
					options.success.apply(options.scope || window, [data, status, xhr, element, settings, options]);
				}
			},
			error: function (xhr, status, errorThrown) {
				getFunction((element && element.getAttribute("data-ajax-failure")), ["xhr", "status", "error"]).apply(element, [xhr, status, errorThrown]);
				if (options.error) {
					options.error.apply(options.scope || window, [xhr, status, errorThrown, element, settings, options]);
				} else {
					//show error message
					document.write(xhr.responseText);
				}
			}
		});

		//settings.data.push({ name: "X-Requested-With", value: "XMLHttpRequest" });

		method = settings.type.toUpperCase();
		if (!isMethodProxySafe(method)) {
			settings.type = "POST";
			settings.headers['X-HTTP-Method-Override'] = method;
			//settings.data.push({ name: "X-HTTP-Method-Override", value: method });
		}

		//console.log('before prepareRequest settings', settings);
		var executeRequest = true;
		if (options.prepareRequest) {
			executeRequest = options.prepareRequest.apply(options.scope || window, [element, settings, options]);
		}

		if (executeRequest !== false) {
			//console.log('settings on execute:', settings);
			$.ajax(settings);
		} else {
			if (options.requestAborted) {
				options.requestAborted.apply(options.scope || window, [element, settings, options]);
			}
		}
	};

	function getFunction(code, argNames) {
		var fn = window, parts = (code || "").split(".");
		while (fn && parts.length) {
			fn = fn[parts.shift()];
		}
		if (typeof (fn) === "function") {
			return fn;
		}
		argNames.push(code);
		return Function.constructor.apply(null, argNames);
	}

	function isMethodProxySafe(method) {
		return method === "GET" || method === "POST";
	}

	function asyncOnBeforeSend(xhr, method) {
		if (!isMethodProxySafe(method)) {
			xhr.setRequestHeader("X-HTTP-Method-Override", method);
		}
	}

	function asyncOnSuccess(element, data, contentType, options) {
		var mode;

		if (contentType.indexOf("application/x-javascript") !== -1) {  // jQuery already executes JavaScript for us
			return;
		}

		mode = ((element && element.getAttribute("data-ajax-mode")) || options.mode || "").toUpperCase();
		$((element && element.getAttribute("data-ajax-update")) || options.update).each(function (i, update) {
			var top;

			switch (mode) {
				case "BEFORE":
					top = update.firstChild;
					$("<div />").html(data).contents().each(function () {
						update.insertBefore(this, top);
					});
					break;
				case "AFTER":
					$("<div />").html(data).contents().each(function () {
						update.appendChild(this);
					});
					break;
				case "REPLACE-WITH":
					$(update).replaceWith(data);
					break;
				default:
					$(update).html(data);
					break;
			}
		});
	}

	function validate(form) {
		var validationInfo = $(form).data(data_validation);
		return !validationInfo || !validationInfo.validate || validationInfo.validate();
	}

	$(document).on("click", "a[data-ajax=true]", function (evt) {
		evt.preventDefault();
		cms.ajax(this, {
			url: this.href,
			type: "GET",
			data: []
		});
	});

	$(document).on("click", "form[data-ajax=true] input[type=image]", function (evt) {
		var name = evt.target.name,
			target = $(evt.target),
			form = $(target.parents("form")[0]),
			offset = target.offset();

		form.data(data_click, [
			{ name: name + ".x", value: Math.round(evt.pageX - offset.left) },
			{ name: name + ".y", value: Math.round(evt.pageY - offset.top) }
		]);

		setTimeout(function () {
			form.removeData(data_click);
		}, 0);
	});

	$(document).on("click", "form[data-ajax=true] :submit", function (evt) {
		var name = evt.currentTarget.name,
			target = $(evt.target),
			form = $(target.parents("form")[0]);

		form.data(data_click, name ? [{ name: name, value: evt.currentTarget.value }] : []);
		form.data(data_target, target);

		setTimeout(function () {
			form.removeData(data_click);
			form.removeData(data_target);
		}, 0);
	});

	$(document).on("submit", "form[data-ajax=true]", function (evt) {
		var clickInfo = $(this).data(data_click) || [],
			clickTarget = $(this).data(data_target),
			isCancel = clickTarget && clickTarget.hasClass("cancel");
		evt.preventDefault();
		if (!isCancel && !validate(this)) {
			return;
		}
		cms.ajax(this, {
			url: this.action,
			type: this.method || "GET",
			data: clickInfo.concat($(this).serializeArray())
		});
	});

})(jQuery, window);