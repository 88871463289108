(function ($) {

    /*
	* Elemente anhand der aktuellen Responsive Klasse verschieben
	* Beispiel Konfigurations-Objekt:
	*	{
	*		targetRules: {
	*			'xs sm':	'#artikelSuche',
	*			'md lg':	'#advanced-search-mobile'
	*		}
	*	}
	*/
    $.fn.moveResponsiveDiv = function (options) {
        this.each(function (idx, el) {
            var $this = $(this);

            //Initiale Verschiebung prüfen
            doMoveDivision($this, options, detectBootstrapEnv());

            //Verschiebung bei änderung der responsive Klasse
            $(document).on('bootstrapEnvChanged', function (evt, env) {
                //console.log('env changed: ' + env);
                doMoveDivision($this, options, env);
            });
        });
    };

    /*
	* Element verschieben
	*/
    function doMoveDivision($el, options, env) {
        var $parent = $el.parent();
        var target;
        for (var key in options.targetRules) {
            if (key.indexOf(env) > -1) {
                target = options.targetRules[key];
                break;
            }
        }

        if (target) {
            var $target = $(target);
            if (!$parent.is($target)) {
                //console.log('move to: ' + target);
                $el.appendTo($target);
            }
        }

    };


    /*
	* Event zu Erkennung der Änderung der responsive Klassen
	*/
    var currentEnv = '';
    $(window).resize(function () {
        var env = detectBootstrapEnv();
        if (env != currentEnv) {
            currentEnv = env;
            $(document).trigger('bootstrapEnvChanged', env);
        }
    });

    var $elDetectEnv;
    function detectBootstrapEnv() {
        var envs = ['xs', 'sm', 'md', 'lg', 'xl'];

        if (!$elDetectEnv) {
            $elDetectEnv = $('<div class="device-xs d-block d-sm-none"></div><div class="device-sm d-none d-sm-block d-md-none"></div><div class="device-md d-none d-md-block d-lg-none"></div><div class="device-lg d-none d-lg-block d-xl-none"></div><div class="device-xl d-none d-xl-block"></div>');
            $elDetectEnv.appendTo($('body'));
        }

        for (var i = envs.length - 1; i >= 0; i--) {
            var env = envs[i];
            if ($('.device-' + env).is(':visible'))
                return env;
        };
    }

})(jQuery);