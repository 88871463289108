// Navigation initialisieren
$(function () {
	//$('.d-nav-canvas-toplevel').navigationDesktopCanvas({
	//	collapsibleMenuStartLevel: 0
	//});

    $('.d-nav-menu-toplevel').navigationDesktopMenu({
        collapsibleMenuStartLevel: 1,
        openToplevelWithClick: false
    });

    $('.m-nav').navigationMobile();

    $('[data-toggle="popover"]').popover();
});