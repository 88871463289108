import React, { useEffect } from "react";
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import loadable from '@loadable/component';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import ShopStore from './ShopStore';
import { createAsyncAction, errorResult, successResult } from "pullstate";
import LoadingIndicator from './LoadingIndicator';
import { ModalContainer } from '../react_components/ModalContainer';
import browserUpdate from 'browser-update';

const loadMessages = loadable(props => import(`../locales/${props.language}/messages`));
const loadPlurals = loadable(() => import('make-plural/plurals'));
const Shop = loadable(() => import('./Shop'));

const loadTranslations = createAsyncAction(async ({ language, locale }) => {
    const [messages, plurals] = await Promise.all([
        loadMessages.load({ language: language }),
        loadPlurals.load()
    ]);

    const localeData = {};
    localeData[language] = { plurals: plurals[language] };
    i18n.loadLocaleData(localeData);
    i18n.load(language, messages.messages);
    i18n.activate(language, locale);
    //console.log('language activated', localeData, language, messages, plurals[language]);

    return successResult();
});

const LoadableShop = (props) => {
    const [isLoaded] = loadTranslations.useBeckon({ language: props.language, locale: props.locale });

    useEffect(() => {
        browserUpdate({ required: { e: -6, f: -6, o: -6, s: -6, c: -6 }, insecure: true, unsupported: true, api: 2021.04 });

        Shop.preload();

        ShopStore.update([
            s => { s.basket = props.basket; },
            s => { s.language = props.language; },
            s => { s.locale = props.locale; },
            s => { s.basePath = props.basePath; },
            s => { s.shopConfiguration = props.shopConfiguration; }
        ]);
    }, []);

    return (
        <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
            <BrowserRouter>
                <LastLocationProvider>
                    <Choose>
                        <When condition={isLoaded}>
                            <Shop {...props} fallback={LoadingIndicator} />
                        </When>
                        <Otherwise>
                            {LoadingIndicator}
                        </Otherwise>
                    </Choose>
                </LastLocationProvider>
            </BrowserRouter>
            <ModalContainer />
        </I18nProvider>
    );
};

export default LoadableShop;