import BootstrapModal from '../Scripts/bootstrap-modal';
import * as Ladda from 'ladda';

(function ($, window) {
    var record = window.record = window.record || {};

    $(document).on('click', 'a.action-share[data-document-detail-id]', function (e) {
        e.preventDefault();
        var id = $(this).data('document-detail-id');
        var url = $(this).attr('href');
        if (id && url) {
            record.contentShareDialog(url + '/ShareDocument/' + id, url + '/ShareDocumentLink/' + id, { title: record.DEFAULT_OPTIONS_contentShareDialog.shareDocument });
        }
    });

    $(document).on('click', 'a.action-share[data-reference-id]', function (e) {
        e.preventDefault();
        var id = $(this).data('reference-id');
        var url = $(this).attr('href');
        if (id && url) {
            record.contentShareDialog(url + '/ShareReference/' + id, url + '/ShareReferenceLink/' + id, { title: record.DEFAULT_OPTIONS_contentShareDialog.shareReference });
        }
    });

    $(document).on('click', 'a.action-share[data-salescontact-id]', function (e) {
        e.preventDefault();
        var id = $(this).data('salescontact-id');
        var url = $(this).attr('href');
        if (id && url) {
            record.contentShareDialog(url + '/ShareSalesContact/' + id, url + '/ShareSalesContactLink/' + id, { title: record.DEFAULT_OPTIONS_contentShareDialog.shareSalesContact });
        }
    });

    $(document).on('click', 'a.action-share[data-job-id]', function (e) {
        e.preventDefault();
        var id = $(this).data('job-id');
        var url = $(this).attr('href');
        if (id && url) {
            record.contentShareDialog(url + '/ShareJob/' + id, url + '/ShareJobLink/' + id, { title: record.DEFAULT_OPTIONS_contentShareDialog.shareJob });
        }
    });

    $(document).on('click', 'a.action-share[data-news-id]', function (e) {
        e.preventDefault();
        var id = $(this).data('news-id');
        var url = $(this).attr('href');
        if (id && url) {
            record.contentShareDialog(url + '/ShareNews/' + id, url + '/ShareNewsLink/' + id, { title: record.DEFAULT_OPTIONS_contentShareDialog.shareNews });
        }
    });

    /**********************************************
    * Sharing Dialog
    ***********************************************/
    record.contentShareDialog = function (urlShare, urlGetLink, options) {
        options = $.extend({}, record.DEFAULT_OPTIONS_contentShareDialog, options);
        if (!options.title)
            options.title = options.shareDocLibrary;

        new BootstrapModal({
            title: options.title,
            nl2br: false,
            message: $('<div><div style="text-align:center;margin:10px 0;"><i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="vertical-align:middle;"></i></div></div>').load(urlShare),
            buttons: [
                {
                    id: 'btn-getlink',
                    label: options.getLink,
                    cssClass: 'btn btn-primary',
                    action: function (dialog, e) {
                        dialog.getButton('#btn-share').hide();
                        $(e.target).hide();
                        dialog.setMessage($('<div><div style="text-align:center;margin:10px 0;"><i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="vertical-align:middle;"></i></div></div>').load(urlGetLink))
                    }
                },
                {
                    id: 'btn-share',
                    label: options.share,
                    cssClass: 'btn btn-primary',
                    action: function (dialog, e) {
                        var btnShare = $(this);
                        console.log(btnShare);
                        var $form = dialog.$modalBody.find('form');
                        if ($form.length == 1) {
                            var l = Ladda.create(e.target);
                            l.start();

                            $.ajax({
                                url: $form[0].action,
                                type: "POST",
                                data: $form.serializeArray(),
                                success: function (data, status, xhr) {
                                    dialog.setMessage(data);
                                    if (xhr.getResponseHeader('x-save-success') == 'true') {
                                        console.log(btnShare);
                                        dialog.getButton('#btn-getlink').hide();
                                        btnShare.hide();
                                    }
                                },
                                complete: function () {
                                    l.stop();
                                }
                            });
                        }
                    }
                },
                {
                    id: 'btn-close',
                    cssClass: 'btn btn-secondary',
                    label: options.close,
                    action: function (dialog) {
                        dialog.close();
                    }
                }
            ]
        });

    };

    record.DEFAULT_OPTIONS_contentShareDialog = {
        shareDocument: 'Dokument teilen',
        shareReference: 'Referenz teilen',
        shareDocLibrary: 'Merkliste teilen',
        shareSalesContact: 'Kontakt teilen',
        shareJob: 'Job teilen',
        shareNews: 'News teilen',
        title: null,
        close: 'Schliessen',
        share: 'Teilen',
        getLink: 'Link abrufen'
    };

})(jQuery, window);
