/*** Logo Animation ***/
if ($(".logoslider").length) {

    $(function () {
        var sliderimgleft = $(".imgLeft");
        var sliderimgright = $(".imgRight");
        var slogan = $(".slogan");
        var sloganText = $("#sloganText");

        /* Get Slogan Width */
        slogan.css('display', 'block');
        var sloganWidth = sloganText.width();
        var movePx = sloganWidth / 2 + 40;
        slogan.css('display', 'none');

        function playLogoSlider() {
            sliderimgleft.animate({ "left": "-=" + movePx + "px" }, 1000);
            sliderimgright.animate({ "right": "-=" + movePx + "px" }, 1000);
            setTimeout(function () { slogan.fadeIn(600); }, 800);
            setTimeout(function () {
                slogan.fadeOut(600);
                setTimeout(function () {
                    sliderimgleft.animate({ "left": "+=" + movePx + "px" }, 1000);
                    sliderimgright.animate({ "right": "+=" + movePx + "px" }, 1000);
                    slogan.css('display', 'none');
                }, 300);
            }, 3000);
        }
        playLogoSlider();
        setInterval(function () {
            playLogoSlider();
        }, 6000);
    });

}