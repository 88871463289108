// open the client email with the specified address
window.sendEmail = function (encodedEmail, params) {
    location.href = "mailto:" + window.decodeEmail(encodedEmail) + params;
};

// return the decoded email address
window.decodeEmail = function (encodedEmail) {
    // holds the decoded email address
    var email = "";

    // go through and decode the email address
    for (let i = 0; i < encodedEmail.length;) {
        // holds each letter (2 digits)
        var letter = "";
        letter = encodedEmail.charAt(i) + encodedEmail.charAt(i + 1)

        // build the real email address
        email += String.fromCharCode(parseInt(letter, 16));
        i += 2;
    }

    return email;
};