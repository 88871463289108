/********************************************************************************************
* 
* Plugin:			bs-modal
* Beschreibung:		Dynamische Bootstrap Modal Dialog erzeugen
* 
* ******************************************************************************************/

//Defaults
var defaults = {
    //Titel des Modals
    title: '',

    //Text Message
    message: '',

    //text oder html
    messageType: 'text',

    //alert-type: primary / secondary / success / danger / warning / info / light / dark
    alertType: '',

    //Linebreaks in <br> umwandeln für string message
    nl2br: true,

    //Zusätzliche Klassen für die modal-dialog div
    //z.B: modal-lg / modal-sm / modal-dialog-centered
    dialogClass: '',

    //Modaler Dialog
    modal: false,

    //Auoclose in sekunden
    autoClose: undefined

    //buttons: [
    //    {
    //        //id: '',
    //        cssClass: 'btn btn-default',
    //        label: 'Schliessen',
    //        //attributes: { 'data-test' : '' },
    //        //action: function (dialog, event) { console.log(dialoag, this, event); dialog.close(); }
    //        icon: 'fas fa-times',
    //        dataDismiss: true
    //    }
    //]
};

// Konstruktur
const BootstrapModal = function (options) {
    this.options = $.extend({}, defaults, options);
    this._defaults = defaults;
    this.init();
};

//Statische Funktionen
$.extend(BootstrapModal, {
    //Simpler alert
    alert: function (message, title, options) {
        if (typeof message === 'object') {
            options = message;
            message = null;
        };

        var modal = new BootstrapModal($.extend(
            {}, {
                title: title,
                message: message,
                buttons: [
                    {
                        cssClass: 'btn btn-primary',
                        label: 'OK',
                        icon: 'fas fa-check',
                        dataDismiss: true
                    }
                ]
            }, options
        ));

        return modal;
    },

    //Remote content laden
    load: function (title, url, alertType, options) {
        var modal = new BootstrapModal($.extend(
            {}, {
                title: title,
                message: '<div style="text-align:center"><i class="fas fa-spinner fa-spin fa-3x mb-5 mt-5"></i></div>',
                messageType: 'html',
                alertType: alertType,
                buttons: [
                    {
                        cssClass: 'btn btn-default',
                        label: 'Schliessen',
                        icon: 'fas fa-times',
                        dataDismiss: true
                    }
                ]
            }
            , options));

        $.get(url, function (data, textStatus, xhr) {
            modal.element.find('.modal-body').html(data);
            modal.handleUpdate();
        });

        return modal;
    },

    confirm: function (options) {
        var modal = new BootstrapModal($.extend(
            {}, {
                modal: true,
                dialogClass: 'modal-dialog-centered',
                buttons: [
                    {
                        cssClass: 'btn btn-success',
                        label: options.btnYesLabel ? options.btnYesLabel : 'Ja',
                        icon: 'fas fa-check',
                        action: function (dialog, e) {
                            dialog.close();
                            if (options.callback)
                                options.callback.apply(this, [true, dialog, e]);
                        }
                    },
                    {
                        cssClass: 'btn btn-primary',
                        label: options.btnNoLabel ? options.btnNoLabel : 'Nein',
                        icon: 'fas fa-times',
                        action: function (dialog, e) {
                            dialog.close();
                            if (options.callback)
                                options.callback.apply(this, [false, dialog, e]);
                        }
                    }
                ]
            }, options
        ));

        return modal;
    }
});

// Instanz Methoden und Eigenschaften
$.extend(BootstrapModal.prototype, {
    // Root jquery element
    element: null,

    // Optionen
    options: null,

    // Komponente initialiseren
    init: function () {
        var that = this;
        this.createModal();
    },

    close: function () {
        this.element.modal('hide');
    },

    handleUpdate: function () {
        this.element.modal('handleUpdate');
    },

    getButton(selector) {
        return this.$modalFooter.find(selector);
    },

    createModal: function () {
        var that = this;

        this.$modalTitle = $('<h5>', { class: 'modal-title' }).text(this.options.title);
        this.$modalHeader = $('<div>', { class: 'modal-header' }).append(this.$modalTitle);
        if (!this.options.modal) {
            this.$modalHeader.append($('<button type="button" class="close" data-dismiss="modal" aria-label="Schliessen"><span aria-hidden="true">&times;</span></button>'))
        }
        if (this.options.alertType)
            this.$modalHeader.addClass('alert-' + this.options.alertType);
        if (this.options.autoClose) {
            this.autoCloseIndicator = $('<span>', { class: 'text-muted', style: 'position:absolute;right:40px;' }).text('[' + this.options.autoClose + ']').appendTo(this.$modalHeader);
            //this.autoCloseIndicator = $('<span class="badge badge-secondary" style="position:absolute;right:40px;">' + this.options.autoClose + '</span>').appendTo(header);
        }
        this.$modalBody = $('<div>', { class: 'modal-body' }).append(this.getMessageContent(this.options.message));

        this.$modalFooter = null;
        if (this.options.buttons && this.options.buttons.length) {
            this.$modalFooter = $('<div>', { class: 'modal-footer' });
            $.each(this.options.buttons, function (idx, item) {
                var attributes = this.options = $.extend({}, { class: item.cssClass }, item.attributes);
                if (item.id) { attributes.id = item.id; }
                if (item.dataDismiss) { attributes['data-dismiss'] = 'modal'; }
                var btn = $('<button>', attributes);
                if (item.icon) {
                    btn.append($('<i>', { class: item.icon + ' mr-2' }));
                }
                btn.append(item.label);
                btn.click(function (e) {
                    e.preventDefault();
                    if (item.action)
                        item.action.apply(this, [that, e]);
                });
                that.$modalFooter.append(btn);
            });
        }

        this.$modalContent = $('<div>', { class: 'modal-content' }).append(this.$modalHeader).append(this.$modalBody);
        if (this.$modalFooter)
            this.$modalContent.append(this.$modalFooter);
        this.$modalDialog = $('<div>', { class: 'modal-dialog' + (this.options.dialogClass ? ' ' + this.options.dialogClass : ''), role: 'document' }).append(this.$modalContent);
        this.element = $('<div>', { class: 'modal fade', tabindex: '-1', role: 'dialog' }).append(this.$modalDialog);
        this.element.appendTo('body');
        var options = {};
        if (this.options.modal) {
            options.backdrop = 'static';
            options.keyboard = false;
        }
        this.element.modal(options);

        //remove from dom after hide
        this.element.on('hidden.bs.modal', function () {
            that.element.modal('dispose');
            that.element.remove();
        });

        //init autoclose countdown
        if (this.options.autoClose) {
            var totalTime = this.options.autoClose * 1000;
            var timer = setInterval(function () {
                totalTime -= 100;
                var curTime = Math.ceil(totalTime / 1000);
                that.autoCloseIndicator.text('[' + curTime + ']');
                if (totalTime <= 0) {
                    clearInterval(timer);
                    that.close();
                }
            }, 100);
        }
    },

    getMessageContent: function (rawContent) {
        var content = null;
        if (typeof rawContent === 'function') {
            content = rawContent.call(rawContent, this);
        } else {
            content = rawContent;
        }
        if (this.options.messageType == 'text' && typeof content === 'string') {
            content = this.formatStringContent(content);
        }

        return content;
    },

    formatStringContent: function (content) {
        if (this.options.nl2br) {
            return content.replace(/\r\n/g, '<br />').replace(/[\r\n]/g, '<br />');
        }

        return content;
    },

    setMessage: function (msg) {
        this.$modalBody.html(this.getMessageContent(msg));
    }
});

export default BootstrapModal;