import styles from '../Content/styles/_styles.scss';

//Polyfills
import "@babel/polyfill";

//React
import React from "react";
import ReactDOM from "react-dom";
global.React = React;
global.ReactDOM = ReactDOM;


//React Components
import ShopComponent from './shop/ShopLoader';
global.Components = {
    Shop: ShopComponent
};

//jQuery Plugins and external Libraries
import Bootstrap from 'bootstrap/dist/js/bootstrap';
import 'cookieconsent/src/cookieconsent';
import 'magnific-popup';
import 'flexslider';
import '../Modules/jquery.appear/jquery.appear';
import '../Modules/pannellum/pannellum';
import '../Modules/jquery.mmenu/jquery.mmenu.min.all';
import './jquery.cookie';
import 'jquery-datetimepicker';

//Page Scripts
import './cms.ajax';
import './DocLibrary';
import './DocLibraryShare';
import './mobile-searchfield-panel';
import './MailAddressHandler';
import './scroll-to-top';
import './logo-animation';
import './responsive-element-move';
import './navigation-desktop-menu';
import './navigation-mobile';
import './navigation-init';
import './init';
import bootstrapModal from './bootstrap-modal';
window.BootstrapModal = bootstrapModal;

//Noty Defaults
import Noty from 'noty';
Noty.overrideDefaults({
    theme: 'bootstrap-v4',
    timeout: 4000
});