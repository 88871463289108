(function ($, window) {

    var searchFieldPanel = function (element, options) {
        this.$element = $(element);
        this.options = options;
        this.initialize();
    };

    $.extend(searchFieldPanel.prototype, {
        initialize: function () {
            //console.log('initialize searchFieldPanel', this);
            var that = this;

            this.$body = $('body');
            this.$body.addClass('sfpanel');
            this.$pageOverlay = $('<div class="sfpanel-page-mask"></div>').appendTo(this.$body);
            this.$wrap = this.$element.wrap('<div class="sfpanel-wrap" style="display:none"></div>').parent();
            this.$wrap.prepend('<div class="sfpanel-header"><button type="button" class="close"><span>×</span></button></div>');

            var hideProxy = $.proxy(that.hide, that);
            this.$pageOverlay.click(hideProxy);
            this.$wrap.find('button.close').click(hideProxy);
        },

        show: function() {
            //console.log('show', this);
            this.ensureParentBody(this.$pageOverlay);
            this.ensureParentBody(this.$wrap);

            this.$wrap.show();
            this.$body.addClass('sfpanel-visible');
        },

        hide: function () {
            //console.log('hide', this);
            this.$body.removeClass('sfpanel-visible');
        },

        ensureParentBody: function($el) {
            if ($el.parent()[0] != this.$body[0]) {
                $el.appendTo(this.$body);
            }
        },

        DEFAULTS: {
        }
    });


    $.fn.searchFieldPanel = function (option) {
        return this.each(function () {
            var $this = $(this);
            var options = $.extend({}, searchFieldPanel.prototype.DEFAULTS, typeof option == 'object' && option)
            var data = $this.data('searchFieldPanel');
            if (!data) $this.data('searchFieldPanel', (data = new searchFieldPanel(this, options)));
            if (typeof option == 'string') data[option].apply(data);
        });
    };

    $.support.transition = (function () {
        var thisBody = document.body || document.documentElement,
            thisStyle = thisBody.style,
            support = thisStyle.transition !== undefined || thisStyle.WebkitTransition !== undefined || thisStyle.MozTransition !== undefined || thisStyle.MsTransition !== undefined || thisStyle.OTransition !== undefined;
        return support;
    })();

})(jQuery, window);


